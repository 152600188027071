import React, { FC } from 'react';

import { useDocsDetails } from 'containers/Docs/hooks/useDocsDetails';

import { DocsContextDialogLayout } from './DocsContextDialogLayout';

interface DocsContextDialogProps {
  closeDialog: () => void;
  dialogOpen: boolean;
  docsIds: string[];
}

export const DocsContextDialog: FC<DocsContextDialogProps> = ({
  docsIds,
  ...props
}) => {
  const { data: docsData } = useDocsDetails({
    docsIds,
  });

  if (!docsData) {
    return null;
  }

  return (
    <DocsContextDialogLayout docsIds={docsIds} hits={docsData} {...props} />
  );
};
