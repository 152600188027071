import React, { FC } from 'react';

import { Dialog, Typography } from '@mui/material';

import CollectionDialogContent from 'common/components/Dialogs/CollectionDialogContent/CollectionDialogContent';
import DialogActionTitle from 'common/components/Dialogs/DialogActionTitle';
import { endsLikeChunk, endsLikeDoc } from 'common/utils/docIdHelpers';
import DocsCollection from 'containers/Docs/DocsCollection';
import RetrievalUnit from 'containers/RetrievalUnit/RetrievalUnit';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { DocCardComposition } from 'containers/User/User.enum';

import { ChunkCard } from './ChunkCard';

export interface DocsContextDialogLayoutProps {
  closeDialog: () => void;
  dialogOpen: boolean;
  docsIds?: string[];
  hits: RetrievalUnitData[];
}

export const DocsContextDialogLayout: FC<DocsContextDialogLayoutProps> = ({
  closeDialog,
  dialogOpen,
  docsIds = [],
  hits,
}) => (
  <Dialog
    aria-label="Doc context"
    maxWidth="md"
    open={dialogOpen}
    fullWidth
    onClose={closeDialog}
  >
    <DialogActionTitle onClose={closeDialog}>
      <Typography variant="body1">Documents used in the chat</Typography>
    </DialogActionTitle>

    <CollectionDialogContent dividers>
      <DocsCollection organizeDocIds={docsIds}>
        {hits
          ?.filter((doc) => endsLikeDoc(doc.document.id))
          .map((doc) => (
            <RetrievalUnit
              cardComposition={DocCardComposition.Compressed}
              data={doc}
              key={doc.document.id}
            />
          ))}
        {docsIds?.filter(endsLikeChunk).map((chunkId) => (
          <ChunkCard chunkId={chunkId} key={chunkId} />
        ))}
      </DocsCollection>
    </CollectionDialogContent>
  </Dialog>
);
