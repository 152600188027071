import React, { FC, ReactNode, useMemo } from 'react';

import { Evidence } from 'api/chatApi/chatApi.types';
import { getDocumentHitUrl } from 'api/chatApi/chatApi.utils';
import { isExist } from 'common/utils/assert';
import { useOrganizeDocIds } from 'containers/Docs/hooks/useOrganizeDocIds';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';

import { useAllHitsFromUrl } from '../hooks/useAllHitsFromUrl';

import { EvidenceButtonWithDialogLayout } from './EvidenceButtonWithDialogLayout';

interface EvidenceButtonWithDialogProps {
  answerContent: ReactNode;
  evidences: Evidence[];
  hits?: RetrievalUnitData[];
}

export const EvidenceButtonWithDialog: FC<EvidenceButtonWithDialogProps> = ({
  evidences,
  hits,
  ...props
}) => {
  const docsUrl = useMemo(() => {
    return evidences?.map(getDocumentHitUrl).filter(isExist) ?? [];
  }, [evidences]);

  const { docsData } = useAllHitsFromUrl({
    docsUrl,
  });

  const organizeDocIds = useOrganizeDocIds(docsData);

  const customHits = useMemo(() => {
    if (!hits) {
      return [];
    }

    const customDocIds = evidences
      .filter((item) => item.documentHitUrl?.startsWith('custom://'))
      .map((item) => item?.documentHitUrl?.split('custom://')[1]);

    return hits.filter(({ document }) => customDocIds.includes(document.id));
  }, [evidences, hits]);

  const organizeCustomDocIds = useOrganizeDocIds(customHits);

  const allHits = useMemo(
    () => [...customHits, ...docsData],
    [customHits, docsData]
  );

  const allDocIds = useMemo(
    () => [...organizeCustomDocIds, ...organizeDocIds],
    [organizeCustomDocIds, organizeDocIds]
  );

  return (
    <EvidenceButtonWithDialogLayout
      evidences={evidences}
      hits={allHits}
      organizeDocIds={allDocIds}
      {...props}
    />
  );
};
