import React, { ReactNode } from 'react';

import { Box } from '@mui/material';

import { Evidence } from 'api/chatApi/chatApi.types';
import { ChunkHighlight } from 'common/interfaces/ChunkHighlight.interfaces';
import { Optional } from 'common/utils/assert';

import { Conversation, ConversationPayload } from '../Chat.types';
import { isPayloadWithHits } from '../hooks/helpers';

import { ConversationMessageItem } from './ConversationMessageItem';

export interface ConversationListProps<T extends ConversationPayload> {
  children?: ReactNode;
  conversation: Conversation<T>;
  initials?: string;
  isEvidenceDialog?: boolean;
  onEvidenceChunkClick?: (
    chunks: ChunkHighlight[],
    docIdOrChunkId: Optional<string>
  ) => void;
  onEvidenceItemClick?: (evidence: Evidence) => void;
}

export const ConversationList = <T extends ConversationPayload>({
  children,
  conversation,
  initials,
  isEvidenceDialog,
  onEvidenceChunkClick,
  onEvidenceItemClick,
}: ConversationListProps<T>) => (
  <Box data-testid="ConversationList">
    {conversation.messages.map((message, index) => (
      <ConversationMessageItem
        hits={
          conversation.payload && isPayloadWithHits(conversation.payload)
            ? conversation.payload.hits
            : undefined
        }
        initials={initials}
        isEvidenceDialog={isEvidenceDialog}
        key={`message-${index}`}
        message={message}
        onEvidenceChunkClick={onEvidenceChunkClick}
        onEvidenceItemClick={onEvidenceItemClick}
      />
    ))}
    {children}
  </Box>
);
