import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { captureException } from '@sentry/react';
import { FiniteStates, FiniteStatesType } from 'app/state/finiteStates.enum';
import { RootState } from 'app/state/store';

import { searchDocuments } from 'api/searchApi';
import { SearchEngineEnum } from 'common/enums';
import { deserializeAxiosError } from 'common/utils/error';
import { parseHostname } from 'common/utils/useParsedHostname';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { SearchPayloadFromURL } from 'containers/Search/models/SearchPayloadFromURL';
import { SearchPayload } from 'containers/Search/SearchPayload.interface';

import { SearchResults } from './SearchResults.interface';

export interface SearchResultsState {
  data: {
    firstPageResults?: SearchResults;
    results: SearchResults;
    scrollPosition: number;
    searchId: string;
    searchPayload: SearchPayload;
  };
  error: string | null;
  fetchState: FiniteStatesType;
}

const searchPayloadFromURL = new SearchPayloadFromURL(
  window.location.search,
  parseHostname(),
  undefined
);

export const initialState: SearchResultsState = {
  data: {
    firstPageResults: {
      data: {
        count: 0,
        items: [],
        searchEngine: SearchEngineEnum.ZetaAlpha,
      },
      pagesCount: 0,
      query: '',
      totalResults: 0,
    },
    results: {
      data: {
        count: 0,
        items: [],
        searchEngine: SearchEngineEnum.ZetaAlpha,
      },
      pagesCount: 0,
      query: '',
      totalResults: 0,
    },
    scrollPosition: 0,
    searchId: '',
    searchPayload: searchPayloadFromURL.getSearchPayload(),
  },
  error: null,
  fetchState: FiniteStates.Idle,
};

export const makeSearch = createAsyncThunk(
  'searchResults/post',
  async (searchPayload: SearchPayload) => {
    try {
      const { data } = await searchDocuments(searchPayload);

      return {
        results: data,
        searchPayload,
        ...(searchPayload.page === 1 ? { firstPageResults: data } : {}),
      };
    } catch (error) {
      captureException(error);
      throw deserializeAxiosError(error);
    }
  }
);

const searchResultsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(makeSearch.pending, (state) => {
        state.fetchState = FiniteStates.Loading;
        state.error = null;
      })
      .addCase(makeSearch.rejected, (state, action) => {
        state.fetchState = FiniteStates.Failure;
        state.error =
          action.error.message || 'Sorry, unexpected error happened';
      })
      .addCase(makeSearch.fulfilled, (state, { payload }) => {
        state.fetchState = FiniteStates.Success;
        state.data.results = payload.results;
        if (payload.firstPageResults !== undefined) {
          state.data.firstPageResults = payload.firstPageResults;
        }
        state.data.searchPayload = payload.searchPayload;
        state.error = null;
      });
  },
  initialState,
  name: 'searchResults',
  reducers: {
    deleteSearchResultByDocId: (state, action: PayloadAction<string>) => {
      state.data.results.data.items = state.data.results.data.items.filter(
        (item) => action.payload !== item.document.id
      );
    },
    setSearchResultsId: (state, action: PayloadAction<string>) => {
      state.data.searchId = action.payload;
    },
    setState: (state, action: PayloadAction<FiniteStatesType>) => {
      state.fetchState = action.payload;
    },
    updateSearchPayload: (state, action: PayloadAction<SearchPayload>) => {
      state.data.searchPayload = action.payload;
    },
    updateSearchQueryString: (state, action: PayloadAction<string>) => {
      state.data.searchPayload.queryString = action.payload;
    },
    updateSearchResultDocData: (
      state,
      action: PayloadAction<RetrievalUnitData>
    ) => {
      const docId = action.payload.document.id;
      state.data.results.data.items = state.data.results.data.items.map(
        (item) => {
          if (docId !== item.document.id) {
            return item;
          }

          return action.payload;
        }
      );
    },
    updateSearchScrollPosition: (state, action: PayloadAction<number>) => {
      state.data.scrollPosition = action.payload;
    },
  },
});

const searchResultsStateSelector = (state: RootState): SearchResultsState =>
  state.searchResults;

const searchResultsDataSelector = (
  state: RootState
): SearchResultsState['data'] => state.searchResults.data;

const selectSearchResultsFetchStateSelector = (
  state: RootState
): FiniteStates => state.searchResults.fetchState;

const searchResultsSelector = (state: RootState): SearchResults | null =>
  state.searchResults.data.results;

const firstPageSearchResultsSelector = (
  state: RootState
): SearchResults | undefined => state.searchResults.data.firstPageResults;

export const selectSearchResults = createSelector(
  searchResultsDataSelector,
  (data) => data
);

export const selectSearchResultsScrollPosition = createSelector(
  searchResultsDataSelector,
  (data) => data.scrollPosition
);

export const selectSearchResultsPayload = createSelector(
  searchResultsDataSelector,
  ({ searchPayload }) => searchPayload
);

export const selectSearchResultsId = createSelector(
  searchResultsDataSelector,
  ({ searchId }) => searchId
);

export const selectSearchResultsHits = createSelector(
  searchResultsSelector,
  (results) => results?.data.items ?? []
);

export const selectSearchResultsDocIdHitMap = createSelector(
  firstPageSearchResultsSelector,
  (firstPageResults) =>
    firstPageResults?.data.items.reduce(
      (acc: { [key: string]: RetrievalUnitData }, hit) => ({
        ...acc,
        [hit.document.id]: hit,
      }),
      {} as { [key: string]: RetrievalUnitData }
    ) ?? {}
);

export const selectFirstPageSearchResultsHitDocIds = createSelector(
  firstPageSearchResultsSelector,
  (firstPageResults) =>
    firstPageResults?.data.items.map(({ document }) => document.id) ?? []
);

export const selectSearchResultsLoading = createSelector(
  selectSearchResultsFetchStateSelector,
  (fetchState) => fetchState === FiniteStates.Loading
);

export const selectSearchResultsError = createSelector(
  searchResultsStateSelector,
  ({ error }) => error
);

export const {
  deleteSearchResultByDocId,
  setSearchResultsId,
  setState,
  updateSearchPayload,
  updateSearchQueryString,
  updateSearchResultDocData,
  updateSearchScrollPosition,
} = searchResultsSlice.actions;

export default searchResultsSlice.reducer;
