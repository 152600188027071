import { useCallback, useState } from 'react';

import { captureException } from '@sentry/react';

import type {
  ChatMessage,
  ChatResponseForm as ChatResponseFormChat,
} from '@zarn/vendor/dist/chat-service';
import type { GenericError } from '@zarn/vendor/dist/saved-results';
import type { ChatResponseForm as ChatResponseFormSearch } from '@zarn/vendor/dist/search';

import { BASE_API_URL } from 'api/apiConfig';
import { requestChatAnswer } from 'api/chatApi/chatApi';
import type {
  ChatContext,
  ChatMessageElement,
} from 'api/chatApi/chatApi.types';
import { serializeChatSearchPayload } from 'api/chatApi/chatApi.utils';
import {
  deserializeChatStreamMessage,
  serializeChatStreamPayload,
} from 'api/chatApi/chatStreamingApi.utils';
import type { NoteDetails } from 'api/notesApi/notesApi.types';
import { sseRequest } from 'api/sse';
import type { BotTypeMessage } from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { getTenantBotType } from 'api/tenantSettingsApi/tenantSettingsApi.utils';
import { isFeatureEnabled } from 'common/components/FeatureFlags/Feature';
import { useAssertTenantSettings } from 'common/hooks/useAssertTenantSettings';
import type { Nullable } from 'common/utils/assert';
import { deserializeAxiosError } from 'common/utils/error';
import {
  parseHostname,
  useParsedHostname,
} from 'common/utils/useParsedHostname';

import type { Conversation } from '../Chat.types';

import { buildChatConversation } from './helpers';

const sendSseRequest = async <T extends object>(
  payload: ChatResponseFormChat,
  onMessage: (message: ChatMessageElement | null) => void,
  conversation: Conversation<T>
) => {
  const { tenant } = parseHostname();

  return new Promise<Conversation<T>>((resolve, reject) => {
    let finalMessage: ChatMessageElement | null = null;

    const getDocumentContext = () => {
      // @ts-ignore
      if (!payload?.context?.from_document_ids) {
        return undefined;
      }
      // @ts-ignore
      return { ...payload.context.from_document_ids };
    };

    const getCustomContext = () => {
      // @ts-ignore
      if (!payload?.context?.custom_context) {
        return undefined;
      }
      // @ts-ignore
      return { ...payload.context.custom_context };
    };

    // TODO: Check what is here
    const documentContext = getDocumentContext();
    const customContext = getCustomContext();

    void sseRequest({
      onError: (err: Error) => {
        reject(err);
      },

      onFinish: async () => {
        resolve({
          ...conversation,
          messages: finalMessage
            ? [...conversation.messages, finalMessage]
            : conversation.messages,
        });
        finalMessage = null;
      },

      onMessage: (message: ChatMessage) => {
        finalMessage = deserializeChatStreamMessage(message);
        onMessage(finalMessage);
      },

      payload: {
        // TODO: Check what is here
        // @ts-ignore
        agent_identifier: payload.bot_type,
        bot_params: payload.bot_params,
        conversation: payload.conversation,
        conversation_context: {
          custom_context: customContext,
          document_context: documentContext,
        },
      },
      // TODO: Check it from the vendor
      url: `${BASE_API_URL}/service/chat/stream?tenant=${
        tenant ?? 'zetaalpha'
      }`,
    });
  });
};

const sendApiRequest = async <T extends object>(
  payload: ChatResponseFormSearch,
  tenant: string,
  conversation: Conversation<T>
): Promise<Conversation<T>> => {
  const response = await requestChatAnswer(payload, tenant);

  return {
    ...conversation,
    messages: response.data.conversation,
  };
};

interface Props<T extends object = {}> {
  botParams?: Record<string, any>;
  context: ChatContext;
  conversation: Nullable<Conversation<T>>;
  onConversationChange: (
    conversation: Conversation<T>
  ) => Promise<NoteDetails | { content: string } | null>;
}

export const useSendChatMessage = <T extends object = {}>({
  botParams,
  context,
  conversation,
  onConversationChange,
}: Props<T>) => {
  const { tenant } = useParsedHostname();
  const { tenantSettings } = useAssertTenantSettings();
  const isSseAvailable = isFeatureEnabled('ff-chat-streaming');

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<GenericError | null>(null);
  const [loadingMessage, setLoadingMessage] =
    useState<ChatMessageElement | null>(null);

  const send = useCallback(
    async (message: BotTypeMessage) => {
      const updatedConversations = buildChatConversation(message, conversation);

      await onConversationChange(updatedConversations);

      const tenantBotType = getTenantBotType(
        updatedConversations.botType,
        tenantSettings.chat
      );

      try {
        setError(null);
        setIsLoading(true);
        setLoadingMessage(null);

        let responseConversation: Conversation<T>;

        if (isSseAvailable) {
          const payload = serializeChatStreamPayload({
            chatResponseForm: {
              botParams,
              botType: tenantBotType,
              context,
              conversation: updatedConversations.messages,
            },
          });
          responseConversation = await sendSseRequest(
            payload,
            (lm) => {
              setLoadingMessage(lm);
            },
            updatedConversations
          );
        } else {
          const payload = serializeChatSearchPayload({
            chatResponseForm: {
              botParams,
              botType: tenantBotType,
              context,
              conversation: updatedConversations.messages,
            },
          });
          responseConversation = await sendApiRequest(
            payload,
            tenant,
            updatedConversations
          );
        }

        await onConversationChange(responseConversation);
        setIsLoading(false);
        setLoadingMessage(null);
        return responseConversation;
      } catch (err) {
        setError(err as Error);
      } finally {
        setIsLoading(false);
        setLoadingMessage(null);
      }
    },
    [
      conversation,
      onConversationChange,
      tenantSettings.chat,
      context,
      botParams,
      isSseAvailable,
      tenant,
    ]
  );

  const sendMessage = useCallback(
    async (botMessage: BotTypeMessage) => {
      try {
        setIsLoading(true);
        setError(null);

        return await send(botMessage);
      } catch (err) {
        captureException(err);
        setError(deserializeAxiosError(err));
      } finally {
        setIsLoading(false);
      }
      return null;
    },
    [send]
  );

  return { error, isLoading, loadingMessage, sendMessage };
};
